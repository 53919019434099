import Footer from "../components/Footer";
import BookingPanel from "../components/HomeComponents/BookingPanel";
import HeroBanner from "../components/HomeComponents/HeroBanner";
import JourneyTimeline from "../components/HomeComponents/JourneyTimeline";
import PlacesList from "../components/HomeComponents/PlacesList";
import RoomList from "../components/HomeComponents/RoomList";
import RoomsQuickView from "../components/HomeComponents/RoomsQuickView";
import ThingsToDo from "../components/HomeComponents/ThingsToDo";
function Home() {
  return (
    <div>
      <HeroBanner />
      <BookingPanel />
      {/* <RoomsQuickView /> */}
      <RoomList />
      <JourneyTimeline />
      <ThingsToDo />
      <PlacesList />
      <Footer />
    </div>
  );
}
export default Home;
