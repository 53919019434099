// src/components/Footer.js
import React from "react";
import "./Footer.css";
import Map from "./Map";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>GoldenKuddleBeachResort</h3>
          <p>
            Escape to the tranquility of our serene beach resort, nestled in the
            heart of nature. Experience relaxation like never before!
          </p>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/rooms">Rooms</a>
            </li>
            <li>
              <a href="/things-to-do">Things to Do</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>123 Beach Road, Gokarna, India</p>
          <p>Email: info@goldenkuddlebeachresort.com</p>
          <p>Phone: +91 98765 43210</p>
        </div>
        <div className="footer-section map-section">
          <h3>Location</h3>
          <Map />
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 GoldenKuddleBeachResort. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
