// src/components/StickyHeader.js
import React, { useState, useEffect } from "react";
import "./StickyHeader.css";

const StickyHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setIsSticky(offset > 100); // When the scroll is more than 100px, make header sticky
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`header ${isSticky ? "sticky" : ""}`}
      style={{
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          fontSize: 26,
          color: "darkgoldenrod",
          fontWeight: 800,
        }}
      >
        Golden Kudle Beach Resort
      </div>
      <nav className="nav" style={{ margin: "10px 30px" }}>
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default StickyHeader;
