import React, { useState } from "react";

function BookingPanel() {
  const [rooms, setRooms] = useState(1);
  const [adults, setAdults] = useState(1);

  const incrementRooms = () => setRooms(rooms + 1);
  const decrementRooms = () => rooms > 1 && setRooms(rooms - 1);

  const incrementAdults = () => setAdults(adults + 1);
  const decrementAdults = () => adults > 1 && setAdults(adults - 1);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#e7e7e7d6",
        minHeight: 100,
        padding: "20px 0",
      }}
    >
      <div
        style={{
          width: "60%",
          padding: 20,
          background: "#fff",
          position: "relative",
          top: -50,
          border: "1px solid #ccc",
          borderRadius: 20,
          boxShadow: "0px 3px 13px 0px #5955556e",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {/* Check-in Input */}
        <div style={{ flex: "1 1 200px", minWidth: "100px" }}>
          <label
            style={{
              display: "block",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Check-in
          </label>
          <input
            type="date"
            style={{
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              width: "100%",
              boxSizing: "border-box",
              fontSize: "16px",
              fontFamily: "'Roboto', sans-serif",
              backgroundColor: "#f9f9f9",
              color: "#333",
              outline: "none",
              transition: "border-color 0.3s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007bff")}
            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
          />
        </div>

        {/* Check-out Input */}
        <div style={{ flex: "1 1 200px", minWidth: "100px" }}>
          <label
            style={{
              display: "block",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Check-out
          </label>
          <input
            type="date"
            style={{
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              width: "100%",
              boxSizing: "border-box",
              fontSize: "16px",
              fontFamily: "'Roboto', sans-serif",
              backgroundColor: "#f9f9f9",
              color: "#333",
              outline: "none",
              transition: "border-color 0.3s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007bff")}
            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
          />
        </div>

        {/* Rooms Input */}
        <div style={{ flex: "1 1 150px", minWidth: "100px" }}>
          <label
            style={{
              display: "block",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Rooms
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#f9f9f9",
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              fontSize: "16px",
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            <button
              onClick={decrementRooms}
              style={{
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
                fontSize: "18px",
              }}
            >
              -
            </button>
            <span style={{ margin: "0 15px" }}>{rooms}</span>
            <button
              onClick={incrementRooms}
              style={{
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
                fontSize: "18px",
              }}
            >
              +
            </button>
          </div>
        </div>

        {/* Adults Input */}
        <div style={{ flex: "1 1 150px", minWidth: "100px" }}>
          <label
            style={{
              display: "block",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Adults
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#f9f9f9",
              padding: "12px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              fontSize: "16px",
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            <button
              onClick={decrementAdults}
              style={{
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
                fontSize: "18px",
              }}
            >
              -
            </button>
            <span style={{ margin: "0 15px" }}>{adults}</span>
            <button
              onClick={incrementAdults}
              style={{
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "4px",
                padding: "5px 10px",
                cursor: "pointer",
                fontSize: "18px",
              }}
            >
              +
            </button>
          </div>
        </div>

        {/* Book Now Button */}
        <div
          style={{
            flex: "1 1 100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <button
            style={{
              padding: "12px 20px",
              backgroundColor: "darkgoldenrod",
              color: "#fff",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              fontSize: "16px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              width: "100%",
              marginTop:"11px"
            }}
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default BookingPanel;
