function HeroBanner() {
  return (
    <div>
      <img
        style={{ width: "100%" }}
        src={"https://myholidayhappiness.com/uploads/kudle-beach-8544.jpg"}
        alt="hero banner"
      />
    </div>
  );
}

export default HeroBanner;
